@media print {
    html.leads {
        .ui3-container {
            background-color: white;
            border-color: white;
        }

        #lead-details-container {
            .actions {
                display: none;
            }
        }
    }
}

html.leads {
    // Used by lead return
    .error, .field-validation-error {
        color: red;
    }
}

.ui3-panel {
    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 9px;
    }

    .display-group, .input-group {
        label {
            display: block;
            margin: 0 0 7px 0;
            font-size: 11pt;
            color: black;
            font-weight: bold;
        }

        span, input, select, text, textarea, pre {
            display: block;
            margin: 0 0 7px 0;
        }

        & + .display-group, & + .input-group {
            margin-top: 10px;
        }

        &.horizontal, .horizontal {
            label {
                display: inline-block;
            }

            span, input, select, text, textarea, pre {
                display: inline-block;
                margin-right: 5px;
            }
        }
    }

    &.horizontal-groups, .horizontal-groups {
        .display-group, .input-group {
            display: inline-block;

            & + .display-group, & + .input-group {
                margin-top: 0;
                margin-left: 20px;
            }
        }
    }
}

#profiles-areas-container {
    margin-top: 5px;
    padding: 5px;
    background: #e5e5e5;
}

#lead-details-container {
    // Override some default UI3 styles
    .ui3-container {
        & > h2, & > h3 {
            margin-left: 0;
        }
        .ui3-data-description, td:first-child {
            //text-align: left;
            width: 30%;
        }
        .ui3-data-value, td:nth-child(2) {
            width: 70%;
            padding-top: 5px;
        }

        h2, h3, h4, h5 {
            margin-bottom: 7px;
        }
        input[type="checkbox"]{
            vertical-align: middle;
        }
    }

    .actions {
        margin: 15px 0;

        &.top {
            text-align: right;
        }
    }

    #return-lead-disabled-message {
        margin-top: 10px;
    }

    #info-container {
        .outcome-select {
            width: 272px;
            vertical-align: middle;
        }

        #outcome-set-icon {
            vertical-align: middle;
            font-size: 20px;

            i {
                &.success {
                    color: green;
                }
                &.failure {
                    color: red;
                }
                &.info {
                    color: #03637a;
                }
            }
        }
    }

    #do-not-contact-container {
        background: #e5f1f4;

        .ui3-message {
            padding: 0;
        }

        ul {
            margin-left: 0;
            list-style-type: none;
        }
    }

    #lead-html-container {
        .admin-widget {
            h2 + p {
                margin-top: 10px;
            }
        }
    }
}

#manage-pausing-container, #manage-pausing-widget-container {
    .pause-container {
        // None at this time
    }

    .pause-inner-container {
        p {
            margin-bottom: 0;
        }
        .actions {
            margin-top: 7px;
        }
        &.leads + .calls {
            padding-top: 7px;
            margin-top: 7px;
            border-top: 1px dotted #BBB;
        }
    }

    .pause-button, .resume-button {
        min-width: 106px;   // Firefox and Chrome/IE render widths differently. Size to fit FF for proper alignment.
        text-align: center;
    }

    .subagent {
        h2 {
            margin-left: 0;     // Override the default UI3 style
            margin-bottom:15px;
        }
        h4 {
            margin-bottom:4px;
        }

        .pause-inner-container {
            h2 {
                color:#333;
                font-size:13pt;
            }
        }

        .pause-button, .resume-button {
            min-width: 84px;   // Firefox and Chrome/IE render widths differently. Size to fit FF for proper alignment.
        }
    }
}

#manage-pausing-container {
    .pause-container {
        &.customer {
            margin-bottom: 20px;
        }
    }
}

#manage-pausing-widget-container {
    #manage-pausing-widget-content {
        border-left: 1px solid #c2dfe6;
        border-right: 1px solid #c2dfe6;
        border-bottom: 1px solid #c2dfe6;
    }
}

#pause-leads-container {
    select {
        width: auto;
    }
}
