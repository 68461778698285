/* This file should contain only styles that are to be commonly used across different pages
    Any page or site-specific code should go in the respective project's stylesheet */

* {
    -moz-box-sizing:border-box;
    box-sizing:border-box;
}

.clearfix {
    &:before, &:after {
        display:table;
        content:" ";
    }

    &:after {
        clear:both;
    }
}

// BUS-18151
.ie-only {
    display:none;
}
html {
    &.ie {
        #leads, #calls {
            .dataTable {
                .actions-buttons {
                    margin-right:13px;
                    a {
                        font-size:8pt;
                        margin-right:-9px;
                        font-family:'Lato', 'Arial', 'Helvetica' !important;
                    }
                }
                .outcome-select {
                    width:121px;
                }
            }
        }
        .ie-only {
            display:inherit;
        }
    }
}

body {
    color:#111;
    font-size:10pt;
    font-family:'Lato', Arial, Helvetica, sans-serif;
    // Wrap in a body tag so it carries more weight than generic styles.less styles
    input[type="text"], input[type="date"], input[type="tel"], input[type="number"], input[type="search"]  {
//        height:28px;
//        padding:2px 6px;
        &.datepicker {
            width:125px;
            &:focus {
//                background:#FEF2E6;
            }
        }
    }
    h1, h2, h3, h4, h5, h6 {
        font-family:'Roboto Condensed', 'Arial Narrow', Arial, Helvetica, sans-serif;
        font-weight:normal;
        line-height:.8;
        margin-bottom:5px;
    }
    h1 {
        font-size:20pt;
        margin-bottom:5px;
        line-height:1;
    }
    h2 {
        font-size:15pt;
        margin-bottom:5px;
        span {
            font-weight:normal;
            font-size:16pt;
        }
    }
    h3 {
        font-size:13pt;
        color:#444;
    }
    h4 {
        font-size:12pt;
    }
    h2, h3, h4, h5 {
        margin-bottom:2px;
        color: #0A7F9B;
    }
    h3, h4 ,h5 {
        color:#444;
    }
    p {
        margin-bottom:10px;
    }
    ul {
        margin-left:20px;
        margin-bottom:10px;
        li {
            margin-bottom:5px;
        }
    }
    a {
        color:#228CA5;
        &:hover {
            color:#03637A;
        }
    }
    select {
        &.light-green {
            background:#DCF5E3;
            border:1px solid #C1EECD !important;
            transition:all 500ms ease-out;
        }
    }
    .ui3-green {
        color:rgb(0,116,30);
    }
    .ui3-red {
        color:rgb(230,33,4);
    }
    .ui3-orange {
        color:#fa8109;
    }
    dl {
        margin:10px 0;
        dt {
            font-weight:bold;
        }
        dd {
            margin-bottom:10px;
        }
    }
    .fine-print {
        font-style:italic;
        color:#777;
    }
}

.no-margin {
    margin:0 !important;
}
.no-border {
    border:0 !important;
}
.absolute {
    position:absolute;
}
.disable {
    pointer-events:none;
    cursor:default;
}
.fauxhide {
    height:0 !important;
    min-height:0 !important;
    width:0 !important;
    visibility:hidden !important;
    overflow:hidden !important;
    opacity:0 !important;
    display:block;
    margin:0 !important;
    padding:0 !important;
}
.hidden, .hide {
    display:none;
}
.normal-weight {
    font-weight:normal;
}
.align-center {
    text-align:center;
}
.float-left {
    float:left;
}
.float-right {
    float:right;
}

.ui3-container, .ui3-container-2 {
    input[type="text"], input[type="password"], select, textarea {
        padding: 3px 4px !important;
        border: 1px solid #DDD !important;
        outline: none;
        transition: all 200ms ease;
        border-radius: 2px;
        width:100%;
        &:focus {
            background-color: #F7FBFC;
            border-color: #84BFCD !important;
        }
        &::-ms-clear {
            display: none;
        }
    }
    input[type="text"].input-validation-error,
    input[type="password"].input-validation-error,
    select.input-validation-error,
    textarea.input-validation-error,
    .chosen-container.input-validation-error .chosen-choices,
    .chosen-container.input-validation-error .chosen-single,
    .input-validation-error + .chosen-container a.chosen-single {
        background-color: #FFF8F7 !important;
        border-color: #FC9284 !important;
    }
    textarea {
        width:100%;
        height:100px;
        font-family:Arial, Helvetica, sans-serif;
    }
    input[maxlength="3"]{
        width:30px;
    }
    input[maxlength="4"]{
        width:40px;
    }
    input[maxlength="5"]{
        width:50px;
    }
    input[maxlength="6"]{
        width:60px;
    }
    input[maxlength="7"]{
        width:65px;
    }
    input[maxlength="8"]{
        width:70px;
    }
    input[maxlength="9"]{
        width:75px;
    }
    input[maxlength="10"]{
        width:85px;
    }
    input[maxlength="14"]{
        width:105px;
    }
    input[maxlength="19"]{
        width:150px;
    }
}

.ui3-grid {
    input[type="text"], input[type="password"], select, textarea {
        padding: 3px 4px !important;
        border: 1px solid #DDD !important;
        outline: none;
        transition: all 200ms ease;
        border-radius: 2px;
        &:focus {
            background-color: #F7FBFC;
            border-color: #84BFCD !important;
        }
        &::-ms-clear {
            display: none;
        }
    }
}


.ui3-title {
    font-size:15pt;
}

.ui3-message {
    background:#F2F7F8;
    padding: 6px 8px 6px 8px;
    margin: 7px 0 10px 0;
    border-radius: 2px;
    font-size: 11pt;
    clear:both;
    color:#222;
    border:1px solid #C2DFE6;
//    border-top:0;
//    border-left:0;
//    display:inline-block;
    i {
        display:inline-block;
        vertical-align:middle;
        margin:0 5px 0 5px;
        font-size:17pt;
        color:#0A7F9B;
    }
    h1, h2, h3, h4, p, div {
        display:inline-block;
        vertical-align:middle;
        line-height:1;
        margin:0;
        color:#222;
    }
    h1, h2, h3, h4 {
        font-weight:normal;
        margin-bottom:3px;
        strong, em {
            vertical-align:top;
        }
        a {
            text-decoration:none;
            color:#222;
            &:hover {
                color:#000;
                text-decoration:underline;
            }
        }
    }
    h2 {
        font-size:14pt;
    }
    h3 {
        font-size:13pt;
    }
    h4 {
        font-size:12pt;
    }
    ul {
        li {
            margin-bottom:2px;
        }
        &:last-child {
            margin-bottom:0;
        }
    }
//    &.absolute, &.no-margin {
//        margin:0;
//    }
    &.green {
        background:#009327;
        color:#FFF;
        border:0;
        i {
            color:#FFF;
        }
    }
    &.light-green {
        background:#DCF5E3;
        border-color:#B4EAC2;
        i {
            color:#009327;
        }
    }
    &.yellow {
        background:#FAB109;
        color:#FFF;
        border:0;
        i {
            color:#FFF;
        }
    }
    &.light-yellow {
        background:#FEF3DA;
        border-color:#FEEBC1;
        i {
            color:#FAB109;
        }
    }
    &.red {
        background:#C12712;
        color:#FFF;
        border:0;
        i {
            color:#FFF;
        }
    }
    &.light-red {
        background:#FEE4E1;
        border-color:#FEC8C1;
        i {
            color:#C12712;
        }
    }
    &.red, &.yellow, &.green, {
        h2, h3, h4, p {
            color:#FFF;
        }
    }
    
    .ui3-button {
        font-size:10pt;
        padding:4px 7px;
    }
    
    .validation-summary-errors {
        ul, li {
            margin:0;
            padding:0;
            list-style-type:none;
        }
    }
}

// region Tables
.ui3-table-container {
    margin:3px 0;
    &.no-filtering {
        .dataTables_filter {
            display:none;
        }
    }
    &.no-paging {
        .dataTables_paginate, .dataTables_length {
            display:none;
        }
        &.no-filtering {
            .table-top {
                display:none;
            }
        }
    }
    &.no-info {
        .dataTables_info {
            display:none;
        }
    }
    &.no-sorting {
        th.sorting {
            pointer-events:none;
            cursor:text;
            i {
                display:none;
            }
        }
    }
    &.simple-table {
        .table-top, .table-bottom {
            display:none;
        }
        th.sorting {
            pointer-events:none;
            cursor:text;
            i {
                display:none;
            }
        }
        &.blue-header {
            th {
                padding:7px 7px;
                background:#0A7F9B;
                color:white;
                font-weight:normal;
                &:first-child {
                    border-top-left-radius:2px;
                    border-bottom-left-radius:2px;
                }
                &:last-child {
                    border-top-right-radius:2px;
                    border-bottom-right-radius:2px;
                }
            }
        }
    }
    &.show-all-results {
        .dataTables_paginate, .dataTables_length {
            display:none;
        }
    }
    input[type="text"], input[type="date"], input[type="tel"], input[type="number"], input[type="search"] {
        height:28px;
        padding:2px 6px;
    }

}

.dataTables_wrapper {
    .dataTables_paginate {
        margin-right:10px;
        margin-top:3px;
        .paginate_button {
            float:left;
            display:block;
            text-align:center;
            width:17px;
            height:21px;
            line-height:21px;
            margin:0 1px;
            cursor:pointer;
            color:#DAECF0;
            outline:none;
            transition:all 125ms ease-in;
        }
        .previous, .next {
            font-size:8pt;
            font-family:'FontAwesome';
            &:hover {
                color:#FFF;
            }
        }
        .previous {
            &:before {
                content:"\f053";
            }
        }
        .next {
            &:before {
                content:"\f054";
            }
        }
        span {
            a {
                border-radius:2px;
                font-size:10pt;
                &:hover {
                    color:#FFF;
                }

                &.current {
                    color:#FFF;
                    font-size:12pt;
                    margin-top:-2px;
                }
            }
            span {
                display:block;
                float:left;
                color:#EEF6F8;
                font-weight:bold;
            }
        }
    }   // .dataTables_paginate

    .table-top {
        border-radius:2px;
        padding:7px 7px;
        overflow: hidden;
        font-size:9pt;
        margin-top:-1px;
        background:#0A7F9B;
        > div {
            float:left;
        }
        .dataTables_filter {
            input {
                width:200px;
                &.has-text {
                    background:#D2F3DB;
                    font-weight:bold;
                }
            }
        }
        .dataTables_length {
            float:right;
        }
        .dataTables_paginate {
            float:right;
        }
    }

    .table-bottom {
        font-size:9pt;
        padding:0px 10px;
        overflow:hidden;
        .dataTables_filter {
            display:none;
        }
        .dataTables_info {
            float:left;
        }
        .dataTables_length {
            float:right;
        }
        .dataTables_paginate {
            float:right;
        }
        .paginate_button {
            color:#479FB4;
            &:hover {
                color:#024E60;
            }
        }
        select, input {
            background:#ECF5F7;
        }
        span {
            a {
                color:#024E60;
                &:hover {
                    color:#024E60;
                    background:none;
                }
                &.current {
                    color:#024E60;
                }
            }
            span {
                color:#024E60;

            }
        }
    }   // .table-bottom
}   // .dataTables_wrapper
.ui3-table-widgets + .dataTables_wrapper .table-top {
    background:#2F92AA;
}

.ui3-table-widgets, .ui3-table-widgets-light {
    padding:7px 7px;
    position:relative;
    clear:both;
    min-height:42px;
    background:#0A7F9B;
    border-radius:2px;
    .ui3-table-widget {
        float:left;
        margin-right:10px;
        > label {
            width:100%;
            clear:both;
            display:block;
            color:#FFF;
            font-size: 10pt;
            margin-bottom: 3px;
        }
        select {
            height:28px;
        }
    }
    .ui3-button  {
        height:28px;
        i {
            padding-right:0;
        }
        &.fa {
            padding:6px 14px;
            font-size:12pt;
        }
    }
    .table-button {
        float:left;
        display:block;
        text-decoration:none;
        font-size:14pt;
        color:#FFF;
        margin:3px 12px 0px 12px;
        transition:all 100ms ease-in;
        &:hover {
            color:#DAECF0;
        }
    }
}   // .ui3-table-widgets

.ui3-table-widgets-light {
    background:#2F92AA;
}
.ui3-table-widgets + .ui3-table-widgets-light {
    border-radius:0 0 2px 2px;
    margin-top:-1px;
}

.ui3-table-container table {
    width: 100%;
    font-size:10pt;
    margin:3px 0;
    th, td {
        padding: 4px 6px;
    }
    th {
        text-align: left;
        position:relative;
        outline:none;
        border-bottom:1px solid #FFF;
        i {
            float:right;
            font-size:13pt;
        }
        &.sorting, &.sorting_asc, &.sorting_desc {
            cursor:default;
            &:hover {
                text-decoration:underline;
            }
        }
        &.sorting {
            i {
                font-size:10pt;
                color:#ccc;
                top:3px;
            }
        }
        &.sorting_asc, &.sorting_desc {
            background:#C1EECD;
            border-radius:2px;
            i {
                margin-top:-2px;
            }
        }
        &.no-sorting {
            pointer-events:none;
            i {
                display:none;
            }
        }
    }   // th

    tbody {
        tr {
            &.odd {

                td.sorting_1 {
                    background:#F5FCF7;
                }
            }
            &.even {
                td {
                    background:#F5FAFB;
                    &.sorting_1 {
                        background:#F0FBF3;
                    }
                }
            }
            &.archived {
                td {
                    color:#AAA;
                }
                .pvqc-badge {
                    -webkit-filter: formatString("grayscale({0})", 1) ;
                    filter: formatString("grayscale({0})", 1) ;
                    opacity:.5;
                }
                .archive-button {
                    color:#BBB !important;
                    pointer-events:none;
                    cursor:default;
                }
            }
            &.updated {
                td {
                    background:#DCF5E3 !important;
                }
            }
            .ui3-message {
                margin-top:-3px;
                padding:4px 6px 3px 5px;
                box-shadow:1px 1px 1px 0px rgba(0, 0, 0, 0.7);
                i {
                    font-size:14pt;
                }
            }
            td {
                transition:all 400ms ease-in;
                border-bottom:1px solid #FFF;
                border-top:1px solid #FFF;
                &.dataTables_empty {
                    font-weight:bold;
                    padding:8px;
                    font-size:13pt;
                }
                select {
                    border:1px solid #DDD;
                    height:auto;
                    padding:1px;
                    max-width:200px;
                }
                a.fa {
                    margin-right:5px;
                    font-size:12pt;
                    color:#FA8109;
                    color:#0A7F9B;
                    transition:all 150ms ease-in;
                    outline:none;
                    &:hover {
                        color:#479FB4;
                    }
                    &.fa-pause, &.fa-play {
                        font-size:11pt;
                    }
                }
                .title {
                    display:block;
                    font-size:13pt;
                }
                .subtitle {
                    color:#333;
                }
            }
            .actions-buttons {
                transition:all 50ms ease-in;
                opacity:0;
            }
            &:last-child {
                td {
                    border-bottom:1px solid #DDD;
                }
            }
            &:hover {
                td {
                    border-bottom:1px solid #84BFCD;
                    border-top:1px solid #84BFCD;
                    &.dataTables_empty {
                        border-top:1px solid #FFF;
                    }
                }
                .actions-buttons {
                    opacity:1;
                }
            }
        }   // tr
    }   // tbody

    .ui3-fa-button, .ui3-button {
        background:#FA8109;
        background:#0A7F9B;
        padding:5px 8px 4px 8px;
        padding:3px 6px 2px 6px;
        font-size:10pt;
        &:hover {
            background:#C46200;
            background:#228CA5;
        }
    }

}   // .data-table

.ui3-table-bottom-extras {
    margin-top:3px;
    font-size:10pt;
    color:#333;
    select, input {
        background:#ECF5F7;
    }
    select {
      padding: 3px;
      font-family: 'Lato';
      margin-right: 15px;
      background:#DAECF0;
      font-size: 11pt;
      height: 31px;
    }
}
// endregion



// region Spinner for buttons
.ui3-show-spinner {
    position:relative;
    &:after {
        position:absolute;
        content:url('/Content/images/477.gif');
        right:-25px;
        opacity:0;
        transition:all 150ms ease-in;
        pointer-events:none;
    }
    &.spinner-left {
        &:after {
            left:-25px;
            right:auto;
        }
    }
    &.spinner-replace {
        &:after {
            left:10px;
            right:auto;
            top:4px;
        }
    }
}
html.loading {
    .ui3-show-spinner {
        &:after {
            opacity:1;
        }
        &.spinner-replace {
            background:transparent;
            color:transparent;
            pointer-events:none;
        }
    }
}
// endregion



// region Tabs
.ui3-tabs-content {
    position:relative;
    clear:both;

    > div {
        display: none;  // Hide by default to prevent flickering
    }
}
.ui3-tabs {
    float:left;
    border-radius:2px;
    border-bottom:3px solid #0A7F9B;
    border-bottom:3px solid #FA8109;
    .ui3-tab {
        height:28px;
        line-height:28px;
        text-align:center;
        text-decoration:none;
        font-size:12pt;
        transition:all 250ms ease;
        color:#333;
        margin:0;
        padding:5px 13px;
        border-radius:1px 1px 0 0;
        .fa {
            vertical-align:middle;
            padding-right:2px;
        }
        &:hover {
            background:#D9E8EB;
            background:#FEF2E6;
        }
        &.active {
            color:#FFF;
            background:#0A7F9B;
            background:#FA8109;
            font-weight:normal;
            cursor:default;
            pointer-events:none;
        }
    }
}

.ui3-tab-panel {
    position:absolute;
    width:100%;
    display:none;
}
.ui3-table-widgets-light {
    .ui3-tabs {
        border:0;
    }
    .ui3-tab {
        color:#DAECF0;
        &:hover {
            color:#FFF;
            background:transparent;
        }
        &.active {
            font-weight:bold;
            background:transparent;
        }
    }
}
// endregion


.ui3-container {
//    background: #FAFAFA;
    padding: 10px;
    margin-bottom: 10px;
    border-right: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
    border-color:#CCC;
    border-radius: 3px;
//    overflow:hidden;
//    box-shadow: 1px 1px 1px 0px rgba(0,0,0,.08);
    clear:both;
    table {
        width:100%;
        overflow: visible !important;
    }

//    h2 {
//        font-size:16pt;
//        margin-bottom:5px;
//    }
    > h2, > h3 {
        margin-left:5%;
    }
//    h3 {
//        font-size:15pt;
//    }
//    h4 {
//        font-size:13pt;
//    }

//    ul {
//        li {
//            margin-bottom:5px;
//        }
//    }

    .ui3-data-description, td:first-child {
        text-align: right;
        font-size:11pt;
        padding-right:5px;
        padding-top:5px;
        color:#000;
        vertical-align:top;
        font-weight:bold;
        width:30%;
    }
    .ui3-data-value, td:nth-child(2) {
        font-size:11pt;
        width:50%;
    }
    &.white {
        background:#FFF;
        border-right:0;
        border-color:#EEE;
        h2, h3, h4, h5 {
            color:#024E60;
        }
        &:last-child {
            border-bottom:0;
        }
    }
    &.vertscroll-300 {
        padding: 25px;
        width: 100%;
        height: 300px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    &.no-border {
        border-right: none;
        border-bottom: none;
    }
    &.print-content {
        padding: 25px;
        width: 100%;
    }
}

@media (min-width:768px) {
    #hamburger {
        display: none;
    }
    
    #mobile-footer-links {
        display: none;
    }

    #password-info-mobile {
        display: none;
    }
    
    html.signup {
        .desktopCall {
            display: inline;
        }
        #password-call {
            display: none;
        }
        #thank-you-mobile {
            display: none;
        }
    }
}

@media (max-width:767px) {
    #hamburger {
        display: none;
    }

    html.signup {
        /* Force table to not be like tables anymore */
	    table, thead, tbody, th, td, tr { 
		    display: block; 
	    }

        #signup-breadcrumb > div {
            display: inline-table !important;
            width: 100%;
            padding: 10px 10px 10px 10px;
        }

        #signup-breadcrumb > div i {
            display: inline-block;
        }
	
	    /* Hide table headers (but not display: none;, for accessibility) */
	    thead tr { 
		    position: absolute;
		    top: -9999px;
		    left: -9999px;
	    }
		
	    td { 
		    /* Behave  like a "row" */
		    border: none;
		    border-bottom: none; 
		    position: relative;
	    }
	
	    td:before { 
		    /* Now like a table header */
		    position: absolute;
		    /* Top/left values mimic padding */
		    top: 6px;
		    left: 6px;
		    width: 45%; 
		    padding-right: 10px; 
		    white-space: nowrap;
	    }

        .ui3-data-value, td:nth-child(2) {
            font-size:11pt;
            width:100%;
        }
    
        #main-container { 
            display: block !important;
        }

        #header-container, #main-content-container, #footer-container {
            display: inline !important;
        }

        .actions {
            float: none !important;
            text-align: center;
            padding-top: 10px;
        }

        .ui3-data-description, .ui3-data-value {
            text-align: center;
            padding-bottom: 2px;
            width: unset;
        }

        .ui3-data-description {
            font-size:11pt;
            font-weight: bold;
        }

        .ui3-container h2 {
            text-align: center;
            padding: 10px;
        }

        .ui3-container td {
            width: unset;
        }

        .ui3-data-value input {
            text-align: center;
        }

        .ui3-message p {
            display: inline;
        }

        .chosen-container-single .chosen-single span {
            margin-right: unset;
        }

        .desktop-call {
            display: none;
        }

        #password-call {
            display: block;
            height: 32px;
            width: 146px;
            margin: auto;
            margin-top: 5px;
        }

        #thank-you-desktop {
            display: none;
        }

        .myButton {
	        -moz-box-shadow: 3px 4px 0px 0px #8a2a21;
	        -webkit-box-shadow: 3px 4px 0px 0px #8a2a21;
	        box-shadow: 3px 4px 0px 0px #8a2a21;
	        background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #c62d1f), color-stop(1, #f24437));
	        background:-moz-linear-gradient(top, #c62d1f 5%, #f24437 100%);
	        background:-webkit-linear-gradient(top, #c62d1f 5%, #f24437 100%);
	        background:-o-linear-gradient(top, #c62d1f 5%, #f24437 100%);
	        background:-ms-linear-gradient(top, #c62d1f 5%, #f24437 100%);
	        background:linear-gradient(to bottom, #c62d1f 5%, #f24437 100%);
	        filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#c62d1f', endColorstr='#f24437',GradientType=0);
	        background-color:#c62d1f;
	        -moz-border-radius:18px;
	        -webkit-border-radius:18px;
	        border-radius:18px;
	        border:1px solid #d02718;
	        display:inline-block;
	        cursor:pointer;
	        color:#ffffff;
	        font-family:Arial;
	        font-size:17px;
	        padding:7px 25px;
	        text-decoration:none;
	        text-shadow:0px 1px 0px #810e05;
        }
        .myButton:hover {
	        background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #f24437), color-stop(1, #c62d1f));
	        background:-moz-linear-gradient(top, #f24437 5%, #c62d1f 100%);
	        background:-webkit-linear-gradient(top, #f24437 5%, #c62d1f 100%);
	        background:-o-linear-gradient(top, #f24437 5%, #c62d1f 100%);
	        background:-ms-linear-gradient(top, #f24437 5%, #c62d1f 100%);
	        background:linear-gradient(to bottom, #f24437 5%, #c62d1f 100%);
	        filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#f24437', endColorstr='#c62d1f',GradientType=0);
	        background-color:#f24437;
        }
        .myButton:active {
	        position:relative;
	        top:1px;
        }
            
        #call-us-now {
            width: 100px;
            height: 30px;
            vertical-align: middle;
        }
        
        #mobile-final-text {
            color: #333333;
            line-height: 1.3;
        }

        #header-extras {
            display: inline-block;
        }
        #header-call-us {
            display: inline-block;
        }

        #header-signup-info {
            float: right;
        }

        .header-content {
            text-align: center;
            padding: unset !important;
            position: unset !important;
            width: 75% !important;
        }

        #logo {
            display: inline-block;
        }

        #signup-breadcrumb {
            position: absolute;
            z-index: 10;
            top: 0px;
            width: 60%;
            height: 100%;
            background: rgb(221, 221, 221) none repeat scroll 0% 0%;
            padding-top: 80px;
            left: 0;
            box-shadow: 2px 0 7px 0 rgba(0,0,0,.85),inset -4px -7px 20px 2px rgba(0,0,0,.15);
            transition: all 400ms ease;
            padding-bottom: 1000px;
            margin-bottom: -1000px;
            overflow: hidden;
        }

        #hamburger {
            display: block;
            position: absolute;
            z-index: 100;
            padding: 5px 6px;
            background-color: #ededed;
            background-image: -moz-linear-gradient(center bottom , rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
            border: 2px solid #8A8A8A;
            border-radius: 5px;
            box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.35) inset;
            font-family: "FontAwesome";
            font-style: normal;
            font-weight: normal;
            color: #717171;
            font-size: 25px;
            margin-left: 10px;
            margin-top: 20px;
            cursor: pointer;
        }

        .fa-bars::before {
            font-style : normal;
        }

        .hideMenu {
            left: -1000px !important;
        }

        #button_print {
            display: none;
        }

        #main-form-submit {
            float: unset;
            display: block;
            margin: 5px auto;
        }

        #footer-links {
            display: none;
        }

        #mobile-footer-links, .copyright {
            display: block;
            font-size: 7pt;
        }

        #password-info-desktop {
            display: none;
        }

        #password-info-mobile {
            display: block;
        }

        #agreement-checkbox td {
            display: inline;
        }

        .smaller-font {
            font-size: 9pt;
        }

        #header-call-us, #header-signup-info {
            font-size: 9pt !important;
        }

        #logo img {
            height: 55px;
            float: right;
            width: 93%;
        }

        .vertscroll-300 {
            height: 400px;
            margin: 15px;
            width: 95%;
        }

        #signup-breadcrumb .pending {
            color: #000 !important;
            border-color: #bbbbbb !important;
        }

        #signup-breadcrumb .complete {
            color: #1d7ac2 !important;
            border-bottom: 2px solid #1d7ac2 !important;
        }

        #main-content-inner-container {
            text-align: center;
        }

        #button_email {
            margin-left: unset;
            float: unset;
        }

        #mobile-final-heading {
            margin-bottom: 0px;
        }
    }
}

.ui3-container-2 {
    .ui3-section {
        margin-bottom:5px;
        clear:both;
    }
}
.ui3-section {
    h2 {
        text-transform:uppercase;
        font-size:14pt;
    }
}

.ui3-report-content {
    border-radius: 5px;
    border-left: 2px solid #DDD;
    .instructions {
        color:#555;
    }
}


.ui3-radios-bar.enabled {
    border-radius:1px;
    overflow:hidden;
    padding:5px 0;
    .ui3-radio {
        float:left;
        height:21px;
        &:first-child {
            a {
                border-top-left-radius:2px;
                border-bottom-left-radius:2px;
            }
        }
        &:last-child {
            a {
                border-top-right-radius:2px;
                border-bottom-right-radius:2px;
            }
        }
    }
    a {
        padding:5px 7px;
        text-decoration:none;
        border-right:1px solid #74B5C5;
        font-size:9pt;
        transition:background 150ms ease-in;
        border-color:#FFF;
        background:#eee;
        color:#000;
        &:hover {
            background:#E3E3E3;
        }
        &.checked {
            background:#CEE5EB;
//            background:#FCD884;
        }
    }
}

.align-horizontal > div {
    float:left;
    margin-right:13px;
}

input[type="checkbox"] {
    margin:auto 0 auto 0;
}


// region Grid
.ui3-grid {
    float:left;
    width:100%;
    .ui3-grid-title-wrapper {
        width:100%;
        float:left;
        margin-top:5px;
    }
    &.title-left {
        .ui3-grid-table-wrapper {
            float:left;
            width:80%;
        }
        .ui3-grid-title-wrapper {
            width:20%;
            text-align:right;
            margin:0;
        }
    }

    table {
        float:left;
        width:100%;
        border-collapse:separate;
        border-spacing:3px;
    }

    td {
        border-collapse:separate;
        border-spacing:3px;
        border-radius:2px;
        text-align:center;
        padding:10px 2px;
        border-right:1px solid #EEE;
        border-bottom:1px solid #EEE;
        border-color:#CCC;
//        box-shadow: 1px 1px 1px 0px rgba(0,0,0,.08);
        &.green {
            background:#EDFAF1;
            border-color:#CDF1D7;
        }
        &.yellow {
            background:#FFF9EC;
            border-color:#FDE7B5;
        }
        &.red {
            background:#FFF0EE;
            border-color:#FEDAD6;
        }
    }
    td.group1, .group1 td {
        background:#EEE;
        border-color:#DDD;
    }
    td.group2, .ui3-grid .group2 td {
        background:#E9F3F6;
        border-color:#C9E3E9;
    }
    td.group3, .ui3-grid .group3 td  {
        background:#DAECF0;
        border-color:#B5D8E1;
    }
    td.group3 .ui3-grid-data-description {
        color:#444;
    }
    .ui3-grid-data-description {
        font-size:10pt;
        margin-bottom:2px;
        color:#666;
    }
    .ui3-grid-data-value {
        font-size:15pt;
    }
    .ui3-grid-data-value.long-values {
        font-size:13pt;
    }
    .ui3-section-links {
        float:left;
        margin-left:3px;
        margin-bottom:5px;
        > div {
            float:left;
            margin-right:10px;
            padding:7px 7px;
            background:#479FB4;
            font-size:10pt;
            border-radius:2px;
        }
        a {
            margin:0 6px;
            color:#FFF;
            text-decoration:none;
            &.group-2 {
                background:#CDF1D7;
            }
            &:hover {
                text-decoration:underline;
            }
        }
    }
}
.ui3-grid-spacer td {
    background:none;
    border:0;
    padding:1px 0;
}
// endregion

// region Buttons
.ui3-button {
    padding: 8px 14px;
    color: #FFF;
    border: 0;
    font-size: 11pt;
    cursor: pointer;
    transition: all 250ms ease;
    border-radius: 2px;
    text-decoration:none;
    display:inline-block;
    &:hover {
        color:#FFF;
    }
    + .ui3-button, + .ui3-text-button {
        margin-left:15px;
    }
    &.float-right {
        + .ui3-button, + .ui3-text-button {
            margin-right:15px;
        }
    }
    i {
        padding-right:3px;
        font-size:10pt;
    }

    &.blue {
        background: #03637A;
        &:hover {
            background: #22768A;
        }
    }
    &.light-blue {
        background:#60ACBE;
        &:hover {
            background:#7FBCCB;
        }
    }
    &.green {
        background:#00741E;
        &:hover {
            background:#24883E;
        }
    }
    &.red {
        background:#9B1200;
        &:hover {
            background:#A93324;
        }
    }
    &.orange {
        background:#FA8109;
//        box-shadow:1px 1px 0px 1px #EB7805;
        &:hover {
            background:#EB7805;
        }
    }
    &.light-gray {
        background:#EEE;
        color:#111;
        &:hover {
            background:#ddd;
        }
    }
    &:disabled {
        background:#DDD !important;
        color:#999 !important;
        cursor:default;
    }
    &.smaller {
        padding:5px 7px;
        font-size:10pt;
        i {
            margin-left:2px;
            font-size:8pt;
        }
    }
    &.hidden, &.hide {
        display: none;
    }
}

.ui3-text-button {
    color:#444;
    border:0;
    background:transparent;
    padding-top:9px;
    transition:all 200ms ease;
    text-decoration:underline;
    cursor:pointer;
    font-size:10pt;
    &:hover {
        color:#000;
    }
    &.bigger {
        font-size:11pt;
    }
}
// endregion

// region Plugin tweaks

// region Customize date picker
.pika-single.is-bound {
    border-radius:3px;
    margin-top:5px;
    .pika-button {
        background:#F7F7F7;
        border-radius:2px;
        text-align:center;
        transition:all 100ms ease-out;
        box-shadow:none;
        &:hover {
            background:#0A7F9B !important;
        }
    }
    .is-today {
        button {
            color:#555;
            background:#C2DFE6;
        }
    }
    .is-selected {
        button {
            color:#FFF;
            background:#FA8109;
        }
    }
    .pika-label {
        &:hover {
            text-decoration:underline;
        }
    }
}
// endregion

.vex {
    .vex-content {
        border-radius:3px;
    }
    .vex-dialog-button {
        border-radius:2px !important;
        text-transform:none !important;
    }
    .vex-dialog-button-primary {
        background:#03637A !important;
    }
    .vex-dialog-button-secondary {
        background:#479FB4 !important;
        color:#FFF !important;
    }
    .vex-dialog-message {
        font-size:13pt;
        font-weight:bold;
    }
}

// region Tooltip stuff
.reference-id-tooltip {
    padding-right:2px;
    float:left;
}
// endregion

// endregion

// region Chosen plugin tweaks
.chosen-container {
    width: 100% !important;
}
.chosen-container .chosen-choices {
    background: #FFF;
    border-radius: 3px;
    border: 1px solid #DDD;
    outline: none;
    padding: 0 3px !important;
}
.chosen-container-active .chosen-choices {
    background-color: #f7fbfc;
    border-color: #84bfcd !important;
    box-shadow: none;
}
.chosen-container .search-choice {
    background: #CEE5EB !important;
//    background: #FCD884 !important;
    border: 0 !important;
    box-shadow: none !important;
    border-radius: 2px !important;
}
.chosen-container .chosen-drop {
    border-radius:0 0 3px 3px;
    border-color: #DDD;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
}
.chosen-single {
    background: #FFF !important;
    box-shadow: none !important;
    border: 1px solid #DDD !important;
    border-radius: 2px !important;
    color:#000 !important;
    padding-left:4px !important;
}
.chosen-search input {
    transition: none !important;
    border-radius: 2px !important;
}
//endregion


.ui3-width-25 {
    float:left;
    clear:none;
    + .ui3-width-10,
    + .ui3-width-20,
    + .ui3-width-25,
    + .ui3-width-30,
    + .ui3-width-40,
    + .ui3-width-50,
    + .ui3-width-60,
    + .ui3-width-70 {
        padding-left:10px;
    }
}
.ui3-width-30 {
    float:left;
    clear:none;
    + .ui3-width-10,
    + .ui3-width-20,
    + .ui3-width-25,
    + .ui3-width-30,
    + .ui3-width-40,
    + .ui3-width-50,
    + .ui3-width-60,
    + .ui3-width-70 {
        padding-left:10px;
    }
}
.ui3-width-40 {
    float:left;
    clear:none;
    + .ui3-width-10,
    + .ui3-width-20,
    + .ui3-width-25,
    + .ui3-width-30,
    + .ui3-width-40,
    + .ui3-width-50,
    + .ui3-width-60 {
        padding-left:10px;
    }
}
.ui3-width-50 {
    float:left;
    clear:none;
    + .ui3-width-10,
    + .ui3-width-20,
    + .ui3-width-25,
    + .ui3-width-30,
    + .ui3-width-40,
    + .ui3-width-50 {
        padding-left:10px;
    }
}
.ui3-width-60 {
    float:left;
    clear:none;
    + .ui3-width-10,
    + .ui3-width-20,
    + .ui3-width-25,
    + .ui3-width-30,
    + .ui3-width-40 {
        padding-left:10px;
    }
}
.ui3-width-70, .ui3-width-75 {
    float:left;
    clear:none;
    + .ui3-width-10,
    + .ui3-width-20,
    + .ui3-width-25,
    + .ui3-width-30 {
        padding-left:10px;
    }
}
.ui3-width-80 {
    float:left;
    clear:none;
    + .ui3-width-10,
    + .ui3-width-20,
    + .ui3-width-25 {
        padding-left:10px;
    }
}

.ui3-width-10 {
    width:10%;
}
.ui3-width-20 {
    width:20%;
}
.ui3-width-25 {
    width:25%;
}
.ui3-width-30 {
    width:30%;
}
.ui3-width-40 {
    width:40%;
}
.ui3-width-50 {
    width:50%;
}
.ui3-width-60 {
    width:60%;
}
.ui3-width-70 {
    width:70%;
}
.ui3-width-75 {
    width:75%;
}
.ui3-width-80 {
    width:80%;
}
.ui3-width-90 {
    width:90%;
}
.ui3-width-100 {
    width:100%;
    clear:both;
}

.description-width-30 .ui3-data-description {
    width: 30%;
}
.description-width-40 .ui3-data-description {
    width: 40%;
}
.description-width-50 .ui3-data-description {
    width: 50%;
}

.SumoSelect {
    width:100%;
    font-family:Arial, Helvetica, sans-serif;
    color:#000;
    * {
        box-sizing:content-box;
    }
    p {
        margin-bottom:0;
    }
    .SlectBox {
        width:auto;
        border-color:#DDD;
        &:hover {
            outline:none;
        }
    }
    .CaptionCont {
        min-height:18px;
        font-size:10pt;
        span {
            padding-top:2px;
        }
    }
    span.placeholder {
        color:#999 !important;
        font-style:normal !important;
        font-weight:normal;
    }
    &:hover {
        .CaptionCont {
            border-color:#DDD;
            box-shadow:none;
        }
    }
    &:focus {
        .CaptionCont {
            background-color: #f7fbfc;
            border-color: #84bfcd;
            box-shadow:none;
        }
    }
    > .optWrapper.multiple > .options > li span i,  .select-all > span i {
        box-shadow:none;
    }
    > .optWrapper label {
        font-size:10pt;
    }
}

// region Zebra Datepicker
.Zebra_DatePicker {
    background:#FFF;
    border-color:#FFF;
    border-radius:3px;
    box-shadow:1px 1px 3px rgba(0,0,0,.4);
    .dp_header, .dp_footer {
        td {
            color:#222;
        }
        .dp_hover {
            background:#FEDFC1;
            color:#222;
            border-radius:2px;
        }
    }
    .dp_clear {
        color:#222;
    }
    .dp_monthpicker {
        border-spacing: 3px;
        border-collapse: separate;
    }
    .dp_daypicker td,
    .dp_daypicker th,
    .dp_monthpicker td,
    .dp_yearpicker td {
        background:#f3f3f3;
        border:0;
        border-radius:2px;
        transition:all 150ms ease;
    }
    td {
        &.dp_current {
            color:#03637A;
            font-weight:bold;
            border:1px solid #CCC;
        }
        &.dp_hover {
            background:#FCB36B;
            color:#000;
        }
        &.dp_selected {
            background:#FA8109;
        }
    }
}
// endregion
