#tiles {
    .tile {
        background:#F5F5F5;
        color:#FFF;
        width:50%;
        position:relative;
        height:77px;
        height:83px;
        float:left;
        border: 2px solid #FFF;
        overflow:hidden;
        border-radius:4px;
        transition:all 200ms ease;
        h2 {
            font-size:16pt;
            line-height:1;
            margin:0;
            padding:0;
            color:#FFF;
        }
        p {
            opacity:.95;
            a {
                text-decoration:underline;
            }
        }
        a {
            color:#FFF;
            text-decoration:none;
        }
        &.critical {
            background:#EB2205;
        }
        &.important {
            background:#FB5C47;
        }
        &.neutral {
            background:#FAB109;
            background:rgb(10, 127, 155);
        }
        &.good {
            background:#009327;
        }
        &.empty {
            background-color: #FBFBFB;
        }
        &.row-filler {
            width:100%;
            background-color: #FFF;
            background:#FBFBFB;
            i  {
                color: #eee;
                position: absolute;
                top: -10px;
                left: -54px;
                font-size: 159pt;
                opacity: 1;
            }
            h2, h3 {
                color:#555;
                text-shadow: 1px 1px 0px rgba(0,0,0,.1);
                font-size:15pt;
//                text-align:center;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 93%;
                height: 20%;
                margin: auto;
                line-height:.9;
            }
            h2 {
//                text-align:left;
                font-weight:bold;
                line-height:.75;
                color:#555;
                span {
                    font-size:13pt;
                    font-weight:normal;
                }
            }
            &.n-rows-1 {
                height:110px;
                height:81px;
                i {
                    top: -4px;
                    left: -25px;
                    font-size: 74pt;
                }
                h2, h3 {
                    height:39%;
                    height:49%;
                }
            }
            &.n-rows-2 {
                height:166px;
                h2, h3 {
                    height:34%;
                }
            }
            &.n-rows-3 {
                height:263px;
            }
        }

        > i {
            position: absolute;
            font-size: 68pt;
            opacity: .25;
            left: 0px;
            top: -8px;
            height: auto;
            width: auto;
            transition:all 350ms ease;
            &.fa-pause {
                top:-4px;
            }
            &.fa-phone {
                left:-7px;
                top:-6px;
                transform:rotate(46deg);
            }
        }
        .default-content, .hover-content {
            position:absolute;
            transition:all 400ms ease;
            height:100%;
            width:100%;
            padding:14px 10px 10px 30px;
        }
        .default-content {
            transition: transform 0.4s, opacity 0.4s;

        }
        .hover-content {
            opacity:0;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
            transform: translateX(-30%);
            transition: transform 0.4s, opacity 0.4s;
            a {
                i {

                }
            }
            > i {
                font-size:30pt;
                float:left;
                height:53px;
                width:27px;
                text-align:center;
                opacity:.4;
                &.fa-pause {
                    font-size:17pt;
                }

            }
        }

        &.no-cta {

        }

        &.one-cta {
            .hover-content {
                a {
                    font-size:16pt;
                    line-height:53px;
                }
                .fa-chevron-right {
                    opacity:.7;
                    font-size:21pt;
                    vertical-align:middle;
                }
            }
        }

        &.two-cta {
            .hover-content {
                > div {
                    text-align:right;
                }
                a {
                    font-size:13pt;
                    display:block;
                    margin-left:-5px;
                    margin-bottom:5px;
                    i {
                        opacity:.9;
                        &.fa-play {
                            margin-right:2px;
                        }
                    }
                }
            }
        }

        &:hover {
            &.important {
                background:#EB2205;
                background:#FB452E;
            }
            &.neutral {
                background:#E4A205;
                background:#FBBD2E;
                background:rgb(47,146,170);
            }
            &.good {
                background:#198234;
                background:#21A143;
            }
            &.one-cta, &.two-cta {
                .default-content {
                    opacity:0;
                    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                    transform: translateX(50px);
                    i {
                        left:-70px;
                    }
                }
                .hover-content {
                    opacity:1;
                    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
                    transform: translateX(0px);
                    transition: transform 0.4s, opacity 0.1s;
                }
            }
        }
        &.loading {
            cursor:progress;
            a {
                cursor:progress;
                pointer-events:none;
            }
        }
    }
    &.light {
        .tile {
            background:#FFF;
            color:#111;
            &.important {
                box-shadow:inset 0px 0px 1px 0px rgba(230,33,4,.5);
                i {
                    color:#fb5c47;
                    opacity:.1;
                }
            }
            &.neutral {
                box-shadow:inset 0px 0px 1px 0px rgba(228,162,5,.7);
                i {
                    color:rgb(250,177,9);
                    opacity:.2;
                }
            }
            &.good {

            }
        }
    }
}

#banners {
    .banner {
        margin-bottom:11px;
    }
    h3 {
        font-weight:bold;
        font-size:15pt;
    }
    p {
        margin-bottom:2px;
        font-family:'Arial';
    }
}

#stats {
    .stat-box {
        float:left;
        padding:10px;
        border:2px solid #FFF;
        background: #FFF;
        box-shadow: inset 0px 0px 1px 0px rgba(0,0,0,.3);
        min-height: 162px;
        border-radius: 5px;
        position:relative;
        box-shadow:none;
        background:#F9F9F9;
        &.volume {
            .chart-wrap {
                margin-top:10px;
            }
            p {
                margin-bottom:0;
            }
            .stats-display-wrap {
                width:132px;
                margin:0 auto;
            }
            .numbers {
                line-height:1;
                .amount {
                    color:#3492A8;
                    font-weight:bold;
                }
                &.with-chart {
                    float: left;
                    margin: 23px 0px 0px 8px;
//                    text-align:center;
                    .amount {
                        font-size:17pt;
                    }
                }
                &.no-chart {
                    text-align:center;
                    margin-top:21px;
                    .amount {
                        font-size:24pt;
                    }
                    .divisor {
                        display:block;
                        margin-top:-6px;
                    }
                    .limit {
                        font-size:20pt;
                        vertical-align:middle;
                    }
                }
            }
        }
        &.loading {
            cursor:progress !important;
            a {
                cursor:progress !important;
                pointer-events:none;
            }
        }
        .light-text {
            color:#777;
        }
        .more-details {
            font-size:8pt;
            position: absolute;
            right: 7px;
            bottom: 7px;

            font-size:9pt;
            right:25px;
            bottom:9px;
        }
    }
    h2 {
        text-transform:uppercase;
        color:#555;
        margin-bottom:10px;
        font-size:15pt;
        line-height:.7;
        span {
            font-size:10pt;
        }
    }
    .promo {
        float:left;
        width:100%;
        height:114px;
        h3 {
            font-size:11pt;
            margin-bottom:5px;
        }
        p {
            .used {
                font-size:12pt;
                color:#1B9C3D;
                font-weight:bold;
            }
            .available {
                color:#1B9C3D;
                font-size:10pt;
            }
        }
    }
    .promo-spacer {
        height: 48px;
    }
    .ui3-width-50 {
        .promo {
            width:50%;
        }
    }
    .ui3-width-75 {
        .promo {
            width:33.3%;
        }
    }
    .ui3-width-100 {
        .promo {
            width:25%;
        }
    }
    .chart-wrap {
        float:left;
        position:relative;
        width:70px;
        height:76px;
    }
    .chart {
        position:absolute;
        left:0;
        top:0;
    }
    .min-nav {
        text-align:center;
        color:#AAA;
        margin-bottom:7px;
        a {
            font-size:9pt;
            color:#444;
            text-decoration:none;
            text-transform:uppercase;
            &:hover {
                color:#000;
                text-decoration:underline;
            }
            &.selected {
                font-weight:bold;
                text-decoration:none;
                color:#111;
                cursor:default;
            }
        }
    }
    .spinner {
        opacity:0;
        position: absolute;
        right: 7px;
        top: 10px;
        transition:opacity 150ms ease;
        img {
            width:16px;
        }
    }
    .ui3-grid-data-description {
        font-size:11pt;
        margin-bottom:0;
    }
    .ui3-grid-data-value {
        margin-bottom:7px;
    }
}
#account-info {
    /*p {
        font-size: 11pt;
        text-align: center;
        margin-bottom: 2px;
        font-family: Roboto Condensed;
        line-height: 1;
    }*/
    .fa-power-off {
        font-size: 16pt;
        opacity: .70;
        text-align: center;
        display: block;
    }
}

#returned-leads-legend {
    margin-top:4px;
    .legend-section {
        float:left;
        &.standard-leads {
            margin-left:23px;
            .legend-color {
                background:rgb(252,216,132);
            }
        }
        &.pq-leads {
            margin-left:17px;
            .legend-color {
                background:rgb(250,177,9);
            }
        }
    }
    .legend-color {
        width:11px;
        height:11px;
        border-radius:2px;
        display:inline-block;
    }
    .legend-value {
        font-weight:bold;
        text-align:center;
    }
}

// region Restrictions
#lead-restrictions-chart, #call-restrictions-chart {
    width: 70px;
    height: 70px;
    float: left;
    clear: left;
    margin:5px 0 0 10px;
}
#lead-restrictions-legend, #call-restrictions-legend {
    float: left;
    margin-left: 10px;
    margin-top: 5px;
}
.legend-item {
    margin-bottom: 1px;
    font-size: 10pt;
}
.legend-marker {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 2px;
}
/*#lead-restrictions-total, #call-restrictions-total {
    position: absolute;
    left: 5px;
    top: 90px;
    font-size: 16pt;
    width: 88px;
    text-align: center;
    font-weight: bold;
}*/
// endregion
