html.profiles {
    .profile-header {

        hr {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        &:hover, &.expanded {
            background: #F4F9FB;
            border-color: #2E86B2;
            transition: all 200ms ease-out;
            cursor: pointer;
        }

        .profile-label {
            .fa {
                font-size: 24px;
            }
        }

        .profile-price-summary {
            padding-left: 28px;

            .leads-paused-message,
            .calls-paused-message {
                display: none;
            }

            .paused .leads-paused-message,
            .paused .calls-paused-message {
                display: inline-block;
                font-size: 9pt;
                padding: 1px 3px;
                background-color: #c72900;
                margin-left: 2px;
                font-weight: bold;
                color: white;
                border: 1px solid #c72900;
                border-bottom-left-radius: 2px;
                border-bottom-right-radius: 2px;
            }
        }

        .pricing-surcharge {
            padding-left: 28px;
        }

        .discount {
            padding-left: 28px;
        }
    }

    .profile-content {
        padding-left: 18px;

        hr {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .package-pricing-breakdown {
        ul {
            display: inline-table;
            font-size: 10pt;
            margin: 10px 100px 0 0;

            li {
                list-style: none;

                .price {
                    color: #eb7805;
                }
            }
        }
    }

    .pricing-breakdown,
    .delivery-settings {
        margin: 0;

        li {
            list-style: none;
        }
    }

    .profile-action {
        font-size: 14pt;
        color: #0a7f9b;
        cursor: pointer;
        text-decoration: none;
        text-align: center;

        &.disabled {
            color: rgb(132, 191, 205);
        }

        &.delete {
            a {
                color: #bb0b12;
            }
        }

        &:hover {
            color: #111;
        }
    }

    .areas {
        margin-bottom: 15px;
    }

    .area {
        font-size: 10pt;
        color: #111;
        font-weight: bold;
        padding: 1px 3px;
        float: left;
        background-color: #cee5eb;
        border-radius: 2px;
        margin: 2px;

        &.exclusion {
            background-color: #fc9284;
        }

        i {
            color: red;
            cursor: pointer;
        }
    }

    .areas-legend-title {
        color: gray;
        font-weight: bold;
        text-align: center;
    }

    .legend-exclusion {
        font-size: 10pt;
        color: #111;
        font-weight: bold;
        background-color: #FC9284;
        border-radius: 2px;
        margin: 2px;
        padding: 1px 3px;
    }

    .geo-price {
        color: darkorange;
        border: 1px solid darkorange;
        font-weight: bold;
        border-radius: 2px;
        margin: 1px;
        padding: 1px 2px;
    }

    .geo-discount-final-price {
        color: rgba(0, 57, 255, 0.68);
        border: 1px solid rgba(0, 57, 255, 0.68);
        font-weight: bold;
        border-radius: 2px;
        margin: 1px;
        padding: 1px 2px;
    }

    .geo-discount-percentage {
        color: forestgreen;
        text-align: center;
        border: 1px solid forestgreen;
        border-radius: 2px;
        font-weight: bold;
        margin: 1px;
        padding: 1px;
    }

    .paused {
        color: #c72900;
    }

    .profile-price-description {
        border: 1px solid rgb(3, 99, 122);
        color: rgb(3, 99, 122);
        border-radius: 3px;
        padding: 3px 5px 3px 0;
        font-weight: bold;
        margin-top: 5px;

        .profile-product-description-calls {
            color: white;
            background-color: rgb(3, 99, 122);
            padding: 5px;
            margin-right: 5px;
        }

        .profile-product-description-leads {
            padding: 5px;
            margin-right: 5px;
            border-right: solid 1px;
        }

        .profile-price-amount {
            color: #eb7805;
            font-weight: bold;

            &.active {
                color: green;
            }

            &.inactive {
                color: #c72900;
            }
        }

        .package-description {
            color: #808080;
        }
    }

    .profile-surcharge-description {
        border: 1px solid lightgray;
        background-color: #eee;
        color: #eb7805;
        border-radius: 3px;
        padding: 3px 5px 3px 0;
        font-weight: bold;

        .awl-il-Premium-icon {
            background: url("/Content/images/premium-small.png") no-repeat;
            width: 25px;
            height: 25px;
            margin-left: 5px;
        }

        .awl-il-Qualified-icon {
            background: url("/Content/images/qualified-small.png") no-repeat;
            width: 25px;
            height: 25px;
            margin-left: 5px;
        }

        .iq-Premium-icon {
            background: url("/Content/images/iqpremium-small.png") no-repeat;
            width: 70px;
            height: 16px;
            margin-left: 5px;
            margin-top: 2px;
        }

        .iq-Qualified-icon {
            background: url("/Content/images/iqqualified-small.png") no-repeat;
            width: 70px;
            height: 16px;
            margin-left: 5px;
            margin-top: 2px;
        }

        .profile-surcharge-amount {
            line-height: 1.5;
        }
    }

    .awl-il-Premium-icon {
        background: url("/Content/images/premium-small.png") no-repeat;
        width: 25px;
        height: 25px;
        margin-left: 5px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: bottom;
    }

    .awl-il-Qualified-icon {
        background: url("/Content/images/qualified-small.png") no-repeat;
        width: 25px;
        height: 25px;
        margin-left: 5px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: bottom;
    }

    .iq-Premium-icon {
        background: url("/Content/images/iqpremium-small.png") no-repeat;
        width: 70px;
        height: 16px;
        margin-left: 5px;
        margin-top: 2px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: bottom;
    }

    .iq-Qualified-icon {
        background: url("/Content/images/iqqualified-small.png") no-repeat;
        width: 70px;
        height: 16px;
        margin-left: 5px;
        margin-top: 2px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: bottom;
    }

    .product-header, .area-type-title {
        border-right: 1px solid #0a7f9b;
        padding: 5px 10px 8px;
        background-color: #c2dfe6;
    }

    .area-type-title {
        margin-bottom: 5px;
    }

    .delivery-emails-list,
    .delivery-texts-list {
        margin: 0;

        li {
            font-size: 10pt;
            color: #111;
            padding: 1px 3px;
            margin: 2px;
            list-style: none;
            width: 100%;
        }
    }

    .price-amount {
        color: #eb7805;
        font-weight: bold;
    }

    .filter-item {
        width: 100%;
        word-wrap: break-word;
    }

    .area-discounts-indicator {
        display: inline-block;
        font-size: 10pt;
        border-radius: 3px;
        padding: 3px 5px;
        border: 1px solid forestgreen;
        margin-left: 5px;
        color: forestgreen;
    }
    /* Add Profiles */
    .wizard {
        .steps, .actions {
            ul {
                li {
                    display: inline-block;
                    width: 20%;
                }
            }
        }

        .actions {
            ul {
                li:nth-child(4) a { /* Cancel Button */
                    color: #444444;
                    border: 0;
                    background: transparent;
                    padding-top: 9px;
                    transition: all 200ms ease;
                    text-decoration: underline;
                    cursor: pointer;
                    font-size: 10pt;
                }
            }
        }
    }

    .wizard .content h3.title {
        display: none;
    }

    .wizard .steps {
        margin: 15px 0;
    }

    .wizard .steps ul {
        margin: 0;
    }

    .wizard .steps a {
        display: inline-block;
        color: #333333;
        font-weight: normal;
        cursor: default;
        pointer-events: none;
        height: 28px;
        line-height: 16px;
        text-align: center;
        text-decoration: none;
        font-size: 12pt;
        transition: all 250ms ease;
        margin: 0;
        padding: 5px 13px;
        border-radius: 1px 1px 0 0;
        width: 100%;
    }
    /*.wizard .steps a:hover {
            background: #fef2e6;
        }*/
    .wizard .steps .current a {
        color: white;
        background: #fa8109;
    }

    .wizard .steps .current-info,
    .wizard .steps .number {
        display: none;
    }

    .wizard .actions ul {
        display: inline-block;
        float: right;
        width: 50%;
    }

    .wizard .actions a {
        padding: 8px 14px;
        color: white;
        background: #22768a;
        border: 0;
        font-size: 11pt;
        cursor: pointer;
        transition: all 250ms ease;
        border-radius: 2px;
        text-decoration: none;
        display: inline-block;
    }

    .wizard .actions a:hover {
        background: #22768a;
    }

    .wizard .actions .disabled a {
        background: #dddddd !important;
        color: #999999 !important;
        cursor: default;
    }

    .wizard .actions .disabled a:hover {
        background: #dddddd !important;
        color: #999999 !important;
        cursor: default;
    }

    .noUi-connect {
        background: #c2dfe6;
    }

    .lead-package {
        padding: 15px;
        border: 1px solid #C3D4DF;
        margin-bottom: 5px;
        border-radius: 4px;
        cursor: pointer;

        &.custom {
            background-color: #f8f8f8;
        }

        .select-icon {
            color: forestgreen;
        }

        .lead-package-price {
            color: #eb7805;
        }
    }

    .lead-package:hover,
    .lead-package.selected {
        border: 1px solid #418DAF;
    }

    .carrier {
        border: 1px solid lightgray;
        background-color: #eeeeee;
        border-radius: 2px;
        padding: 1px 3px;
    }

    .schedule-day {
        width: 8%;
        display: inline-block;
        margin-left: 20px;
    }

    .delivery-input {
        display: inline-block;
        width: 10%;
        padding-left: 20px;
    }

    #zipcodeplus-radius-select {
        width: 105px;
    }

    #scfcode-input,
    #areacode-input {
        width: 36px;
    }

    .add-areas-section {
        margin-top: 20px;
    }

    .state-add-button {
        margin-top: 5px;
        margin-left: 10px;
    }

    .email-delivery-list, .text-message-delivery-list {
        margin-top: 15px;

        li {
            font-size: 10pt;
            color: #111;
            font-weight: bold;
            padding: 2px 5px;
            background-color: #cee5eb;
            border-radius: 2px;
            margin: 2px;
            list-style: none;

            i {
                color: red;
                cursor: pointer;
                float: right;
            }

            i.send-test-text-message {
                color: #03637a;
                float: none;
                font-size: 12pt;
                margin-left: 3px;
            }
        }
    }

    .sub-text-help {
        font-size: 9pt;
        color: #666;
        font-style: normal;
        margin-bottom: 10px;
    }

    .filter-value-input {
        display: inline;
        border: 1px solid grey;
        height: 24px;
        padding-left: 5px;
    }

    .ui3-tab-panel {
        position: unset;
    }

    .test-lead-info-toggle {
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px 0 5px;
        font-size: 17pt;
        cursor: pointer;
        color: #0a7f9b;
    }

    .internal-only-filter {
        color: white;
        background-color: #0078e1;
        font-weight: bold;
        border-radius: 2px;
        padding: 2px 4px;
        font-size: 9pt;
    }

    .ui3-tab-panel > h3,
    section > h3 {
        display: none;
    }

    .ui3-tab-panel section .step-number {
        display: none;
    }

    .section-title {
        display: none;
    }

    .section-sub-heading {
        padding-top: 10px;
    }

    .customer-filter-display {
        border: 1px solid darkgray;
        border-radius: 3px;
        padding: 15px;
        margin-bottom: 5px;
    }

    .loader {
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 16px solid #3498db;
        width: 120px;
        height: 120px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
        position: fixed;
        top: 48%;
        left: 48%;
        z-index: 9999;
        transform: translate(-50%, -50%);
    }

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    #sic-filter-ui {
        ul {
            margin-top: 5px;

            li {
                list-style: none;
                padding: 3px;
            }
        }

        .sic-industry-code,
        .sic-group-code-range,
        .sic-division-code-range {
            color: #222222;
            border: 1px solid #c2dfe6;
            border-radius: 2px;
            background: #f2f7f8;
            padding: 2px 3px;
            margin: 0 5px;
            font-weight: bold;
        }

        .sic-division-description:hover,
        .sic-group-description:hover {
            text-decoration: underline;
        }

        .sic-division-description::after,
        .sic-group-description::after {
            content: "\f196";
            color: #03637a;
            font-family: FontAwesome;
            font-weight: normal;
            font-style: normal;
            font-size: 11pt;
            display: inline-block;
            text-decoration: inherit;
        }

        .sic-division-description.open::after,
        .sic-group-description.open::after {
            content: "\f147";
        }
    }

    .modalDialog {
        position: fixed;
        font-family: Arial, Helvetica, sans-serif;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
        z-index: 99999;
        opacity: 0;
        -webkit-transition: opacity 400ms ease-in;
        -moz-transition: opacity 400ms ease-in;
        transition: opacity 400ms ease-in;
        pointer-events: none;
        max-height: 100%;
        overflow-y: auto;
    }

    .modalDialog:target {
        opacity: 1;
        pointer-events: auto;
    }

    .modalDialog > div {
        width: 460px;
        position: relative;
        margin: 10% auto;
        padding: 5px 20px 13px 20px;
        border-radius: 5px;
        background: white;
    }

    .apply {
        position: absolute;
        right: 12px;
        top: 10px;
    }

    .close {
        position: absolute;
        right: 85px;
        top: 10px;
    }

    #manual-entry {
        padding: 10px 20px;

        input[type="text"] {
            border: 1px solid grey;
            height: 24px;
            padding-left: 5px;
            width: 100%;
        }
    }

    .appear-disabled {
        border: 0;
        width: 0;
        height: 0;
        color: white;
    }

    .remove-all {
        float: right;
        font-weight: bold;
        border: 1px solid darkgray;
        border-radius: 2px;
        padding: 1px 3px;
        cursor: pointer;

        i {
            color: red;
        }
    }

    .sic-code-display {
        width: 450px;
        overflow-wrap: break-word;
        margin-bottom: 10px;
    }
}
