#lead-volume-chart-legend {
    font-size:9pt;
    margin-left:83px;
    overflow:hidden;
    .legend-element {
        margin-right:17px;
    }
    .color {
        width:17px;
        height:17px;
        margin-right:4px;
        border-radius:1px;
    }
    .current {
        .color {
            background:#3F741A;
        }
    }
    .projected {
        .color {
            background:#84B761;
        }
    }
}

p.no-data {
    margin-bottom:40px;
    font-style:italic;
}

#time-range-wrapper {
    .time-zone {
        font-style:italic;
        font-size:10pt;
        margin-bottom:3px;
    }
}

#lead-volume-chart {
    margin-bottom: 20px;
}
#max-bid-report {
    min-height: 370px;
}

#custom-date-wrapper {
    overflow: hidden;
}
#custom-date-wrapper input {
    width: 107px;
    margin-right: 10px;
}
h2 .fa-question-circle, h3 .fa-question-circle {
    color: #999;
    font-size: 12pt;
    padding-left: 2px;
    cursor: default;
}

#quote-types-wrapper label {
    margin-right: 10px;
}


#more-profiles-link {
    padding-left: 18px;
    font-weight: bold;
    font-size: 10pt;
}


#report-controls select {
    width: 95%;
}
#report-controls .options {
    margin-left: 3px;
}
#report-controls input[type="submit"] {
    margin-top: 10px;
}

html.loading #ui3-report-content {
    transition: opacity 300ms ease;
    opacity: .3 !important;
    pointer-events:none;
}
html.loading {
    cursor: progress !important;
}


#quote-types-wrapper {
    .input-wrapper {
        display:none;
        width:140px;
        float:left;
    }
    &.auto {
        .auto {
            display:block;
        }
    }
    &.annuity {
        .annuity {
            display:block;
        }
    }
    &.businessbenefits {
        .businessbenefits {
            display:block;
        }
    }
    &.businesspc {
        .businesspc {
            display:block;
        }
    }
    &.disability {
        .disability {
            display:block;
        }
    }
    &.health {
        .health {
            display:block;
        }
    }
    &.home {
        .home {
            display:block;
        }
    }
    &.life {
        .life {
            display:block;
        }
    }
    &.ltc {
        .ltc {
            display:block;
        }
    }
    &.renters {
        .renters {
            display:block;
        }
    }
}

#report-tips {
    float: left;
    clear: both;
    margin-top: 60px;
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-top: 5px;
    }
    li {
        margin-bottom: 5px;
        float: left;
        font-size:10pt;
        width:100%;
        strong {
            font-size:11pt;
            color: #333;
            display:block;
        }
        p {
            width:209px;
            float:left;
            padding-left:5px;
            font-size:10pt;
        }
        i {
            float:left;
            width:18px;
            text-align:center;
        }
        a {
            color: #03637A;
            font-weight:bold;
            &:hover {
                color: #024E60;
            }
        }
        &.pausing {
            i {
                font-size: 13pt;
                color: #67B7DC;
            }
        }
        &.pricing {
            i {
                font-size: 17pt;
                color: #84B761;
            }
            /*p {
                margin-top:2px;
            }*/
        }
        &.limits {
            i {
                font-size: 16pt;
                color: #CD82AD;
            }
//            p {
//                margin-top:3px;
//            }
        }
        &.knockout {
            i {
                font-size: 17pt;
                color: #CB4C51;
            }
        }
        &.chat {
            i {
                font-size: 17pt;
                color: #03637a;
                color: #333;
            }
        }
        &.maxbid {
            i {
                font-size: 16pt;
                color: #03637a;
                color: #333;
            }
        }
        &.pq {
            i {
                font-size: 17pt;
                color: #03637a;
                color: #333;
            }
        }
        &.geo {
            i {
                font-size: 16pt;
                color: #03637a;
                color: #333;
            }
        }
    }
}
