@media print {
    .ui3-print-hidden {
        display: none;
    }
    .ui3-table-widgets {
        display:none;
    }
    .ui3-table-container {
        .table-top, .table-bottom {
            display:none;
        }
        table {
            font-size:10pt;
            th {
                padding:1px;
                font-size:11pt;
                i {
                    display:none;
                }
            }
            td {
                padding:0px;
            }
            tfoot  {
                td {
                    border:0;
                }
            }
        }
    }
}

