// region General Rules
html, body, div, form, label, input, select, textarea, button, h1, h2, h3, h4, h5, h6, img, p, ul, ol, li, dl, dt, dd {
    margin:0;
    padding:0;
    outline:none;
}

html {
    height: 100%;
}

body {
    height: 100%;
//    font-family: Arial, Helvetica, sans-serif;
//    font-size: 11pt;

    // Make sure vex doesn't hide the scrollbar, causing the page to shift back and forth
    &.vex-open {
        overflow: visible !important;
    }
}

//h1, h2, h3, h4, h5, h6 {
//    font-family:'Ubuntu';
//}

a {
    color: #1d7ac2;

    &:hover {
        color: #bb0b12;
    }
}

fieldset {
    border: 1px solid #999999;
    legend {
        padding: 0px 8px;
    }
}

h1 {
    color:#333;
    font-size:18pt;
    span {
        font-weight:normal;
        font-size:14pt;
    }
}

input, select {
    border-radius:2px;
    transition:all 150ms ease-out;
    &.active {
        box-shadow:inset 0px 0px 0px 2px #4074A9;
    }
    &:focus {
        box-shadow:0px 0px 8px -2px rgba(255,255,255,.75);
    }
}
select {
    height:24px;
    padding:2px 4px;
    &.first-option-selected {
        color:gray;
    }
}
// endregion



.align-left {
    text-align:left;
}
.align-center {
    text-align:center;
}
.align-right {
    text-align:right;
}
.valign-top {
    vertical-align: top;
}
.valign-middle {
    vertical-align: middle;
}
.valign-bottom {
    vertical-align: top;
}
.float-left {
    float:left;
}
.float-right {
    float:right;
}
.content-toggle-button {
    .show-content {
        display:block;
    }
    .hide-content {
        display:none;
    }
    &.content-showing {
        .show-content {
            display:none;
        }
        .hide-content {
            display:block;
        }
    }
}

.lead-filters {
    position:absolute;
    right:10px;
    top:-32px;
}

// region Promo Credits
#promo-credits-wrapper {
    float:left;
    width:100%;
    margin-bottom:10px;
    max-height:169px;
    overflow:hidden;
    transition:max-height 200ms ease;
    &.show-more {
        max-height:600px;
        transition:max-height 900ms ease;
    }
    h2 {
        margin-bottom:5px;
    }
    .more-promos-button {
        cursor:pointer;
        float:left;
        font-weight: bold;
        font-size: 10pt;
        color: #222;
        margin:-3px 0 5px 170px;
        &:hover {
            text-decoration:underline;
            color:#111;
        }
    }
}


.promo-credits {
    .promo-credit {
        width:100%;
        float:left;
        .progress-bar {
            width:100%;
            position:relative;
            height:25px;
            margin-top:2px;
            border-radius:2px;
            box-shadow:1px 1px 0 0 rgba(0, 0, 0, 0.1);
            .bar {
                position:absolute;
                height:25px;
                border-radius:2px;
                font-weight:bold;
            }
            .amount-used-bar {
                background:#009327;
                background:url('/Content/images/stripes-green.png');
                color:#FFF;
                width:0;
                text-align:left;
                box-shadow:1px 1px 0 0 rgba(0, 147, 39, 0.8);
                transition:all 600ms ease-out;
            }
            .amount-distributed-bar {
                background:#CCC;
                background:url('/Content/images/stripes-gray.png');
                width:0;
                text-align:right;
                box-shadow:1px 1px 0 0 rgba(0, 0, 0, 0.3);
                transition:all 500ms ease-out;
            }
            .total-credit-bar {
                width:100%;
                background:#F5F5F5;
            }
            .text {
                position:absolute;
                z-index:10;
                top:4px;
                font-weight:bold;
                opacity:0;
                left:auto;
                transition:all 700ms ease-out;
                &.contrast {
                    color:#000;
                }
            }
            .amount-used-text {
                color:#FFF;
                margin-left:7px;
            }
        }   // .progress-bar
    }
}

.single-promo .promo-credits {
    .promo-credit {
        h3 {
            font-size:12pt;
            span {
                font-weight:normal;
                font-size:11pt;
            }
        }
        h4 {
            font-size:10pt;
        }
        .left-side {
            float:left;
            width:62%;
            margin-right:10px;
        }
        .right-side {
            float:right;
            width:36%;
            padding-top:9px;
            span {
                display:block;
            }
            .used {
                font-weight:bold;
                color:#009327;
                font-size:13pt;
                letter-spacing:-1px;
            }
            .available {
                font-size:11pt;
            }
            .to-distribute {
                font-size:11pt;
            }
        }
    }
}

.multi-promo .promo-credits {
    .promo-credit {
        margin-bottom:12px;
        transition:opacity 150ms ease;
        &:hover {
            opacity:0.86;
        }
    }
    .description, .progress-bar, .more-info {
        float:left;
    }
    .description {
        width:201px;
        text-align:right;
        margin-right:10px;
        h3 {
            font-size:11pt;
            line-height:1;
            span {
                display:block;
                font-size:10pt;
            }
        }
    }
    .progress-bar {
        width:400px;
    }
    .more-info {
        font-size:11pt;
        margin-left:10px;
        line-height:1;
        .credit-total {
            font-weight:bold;
            span {
                font-weight:normal;
                font-size:9pt;
            }
        }
        .use-by {
            font-size:10pt;
            font-style:italic;
        }
    }
}

.qtip {
    .amount-used {
        font-weight:bold;
        color:#009327;
        font-size:13pt;
        letter-spacing:-1px;
    }
}
// region

// region Account Overview AC2
#account-overview-wrapper-ac2 {
    padding-top:5px;
    h2, h3, h4, h5 {
        // use these styles while we're using the older AC2 design
        font-family:'Tahoma';
        font-weight:normal;
    }
    h2 {
        font-size:18pt;
        span {
            font-weight:normal;
            color:#98141F;
            font-size:14pt;
        }
    }
}
#account-overview-ac2 {
    clear:both;
}
#account-overview-table-ac2 {
    width:100%;
    tr {
        td {
            border-bottom:1px dotted #CCC;
            padding:4px 3px;
            width:25%;
            &.description {
                text-align:right;
                font-weight:bold;
            }
            &.good {
                color:#007700;
                font-weight:bold;
            }
            &.bad {
                color:#98141F;
                font-weight:bold;
            }
        }
        &:last-child {
            td {
                border-bottom:0;
            }
        }
    }
}
// endregion

html.signup {
    #main-container, #header-inner-container .header-content,
    #main-content-inner-container,
    #footer-inner-container .footer-content {
        min-width: unset;
    }
}
// region Layout
#main-container {
    display: table;
    width: 100%;
    height: 100%;
    min-width: 790px;
    position: relative;
}
#header-inner-container .header-content,
#main-content-inner-container,
#footer-inner-container .footer-content {
    max-width:900px;
    min-width:790px;
    margin:0 auto;
    padding:0 7px;
    position:relative;
}
#header-container, #main-content-container, #footer-container {
    display: table-row;
    clear:both;
}
#header-container, #footer-container {
    height: 1px;
}
#main-content-container {
    height: auto;
}
#main-content-inner-container {
    padding-top: 15px;
    padding-bottom: 15px;
}
#header-one {
    background: #F7F7F7;
    padding: 10px 0;
}
#header-two {
    background: #DDD;
}
#header-one .header-content {
    display: table;
    width: 100%;
    min-height: 60px;
}
#logo {
    display: table-cell;
    vertical-align: middle;

    a {
        display: block;

        img {
            display: block;
            max-height: 60px;
            border: 0;
        }
    }
}
#header-extras {
    display: table-cell;
    vertical-align: middle;
    text-align: right;

    .header-extra-content {
        display: inline-block;
        vertical-align: middle;
        text-align: left;

        & + .header-extra-content {
            margin-left: 20px;
        }

        p {
            margin: 0;

            & + p {
                margin-top: 5px;
            }
        }
    }
}
#nav-menu-container {
    min-height: 39px;
    padding: 10px 0;
    font-size: 12pt;
    font-family: 'Roboto Condensed', 'Arial Narrow', Arial, Helvetica, sans-serif;
}
.nav-menu {
    display: inline-block;
    margin: -10px 0;    // Overlap padding from the container so dropdown hit boxes get the entire height
    padding:0;
    list-style-type:none;
    > li {
        display: inline-block;
        margin: 0;
        padding: 10px;
        cursor:default;
        > a, > span {
            text-decoration:none;
            color:#333;
            transition: all ease-out 200ms;
            border-bottom: 2px solid transparent;
            &:hover {
                color:#333;
                //text-decoration:underline;
                border-bottom: 2px solid #333;
            }
        }
        ul {
            display: none;
            list-style-type: none;
            margin: 0;
            position:absolute;
            z-index:10000;
            padding: 5px 20px 10px 20px;
            margin-top:10px;
            margin-left:-10px;
            background:#F7F7F7;
            border:2px solid #CCC;
            border-top:0;
            li {
                margin:9px 0;
                a, span {
                    text-decoration:none;
                    color:#333;
                    transition: all ease-out 200ms;
                    border-bottom: 1px solid transparent;
                    &:hover {
                        color:#000;
                        //text-decoration:underline;
                        border-bottom: 1px solid #000;
                    }
                }
            }
        }
        &:hover {
            ul {
                display:block;
            }
        }
    }
}
#signup-breadcrumb {
    display: table;
    width: 100%;
    padding: 10px 0;
    border-spacing: 5px 0;

    > div {
        display: table-cell;
        width: 14.2857%;
        padding: 0 10px 5px 10px;
        text-align:center;
        i {
            display:block;
            font-size:13pt;
            margin-bottom:2px;
        }
        &.pending {
            color: #7F7F7F;
            border-bottom: 2px solid #7F7F7F;
        }
        &.complete {
            color: #1D7AC2;
            border-bottom: 2px solid #1D7AC2;
        }
        &.current {
            color: #BB0B12;
            border-bottom: 2px solid #BB0B12;
        }
    }
}
#footer-container {
    background: #F7F7F7;
    text-align:center;
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-size: 10pt;
    ul {
        margin:0;
        padding:0;
        list-style-type:none;
        margin-bottom:5px;
        li {
            display:inline;
            margin-right:15px;
            a {
                color:#555;
                &:hover {
                    color:#111;
                }
            }
        }
    }
}
#footer-one .footer-content {
    padding: 25px 0 10px 0;
}
#footer-two .footer-content {
    padding: 10px 0 25px 0;
    p {
        margin: 0;
    }
}
// Aged Leads File Export Configuration page
#aged-leads.locker {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3); 
    z-index: 9999;
}
#aged-leads .loader {
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 16px solid #3498db;
        width: 120px;
        height: 120px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
        position: fixed;
        top: 48%;
        left: 48%;
        z-index: 9999;
        transform: translate(-50%, -50%);
    }
html.brand-il {
    #header-one {
        background: #DDD;
        color: #333;

        a {
            color: #bb0b12;
            &:hover {
                color: #98141F;
            }
        }
    }
    #header-two {
        background: #555;
    }
    .nav-menu {
        > li {
            > a, > span {
                color: #F5F5F5;
                &:hover {
                    color: #F5F5F5;
                    border-color: #F5F5F5;
                }
            }
            ul {
                background: #7F7F7F;
                border-color: #555;
                li {
                    a, span {
                        color: #F5F5F5;
                        &:hover {
                            color: #F5F5F5;
                            border-color: #F5F5F5;
                        }
                    }
                }
            }
        }
    }
    #signup-breadcrumb {
        > div {
            &.pending {
                color: #BBB;
                border-color: #BBB;
            }
            &.complete {
                color: #F5F5F5;
                border-color: #F5F5F5;
            }
            &.current {
                color: #FA8109;
                border-color: #FA8109;
            }
        }
    }
    #footer-container {
        background: #BBB;
        color: #333;
        ul {
            li {
                a {
                    color: #BB0B12;
                    &:hover {
                        color: #98141F;
                    }
                }
            }
        }
    }
}

html.brand-iq {
    #header-one {
        background: #d2e6ed;
        color: #333;

        a {
            color: #228CA5;
            &:hover {
                color: #03637A;
            }
        }
    }
    #header-two {
        background: #005b7f;
    }
    .nav-menu {
        > li {
            > a, > span {
                color: #f5f5f5;
                &:hover {
                    color: #f5f5f5;
                    border-color: #f5f5f5;
                }
            }
            ul {
                background: white;
                border-color: #005b7f;
                li {
                    a {
                        color: #005b7f;
                        &:hover {
                            color: #005b7f;
                            border-color: #005b7f;
                        }
                    }
                }
            }
        }
    }
    #signup-breadcrumb {
        > div {
            &.complete {
                color: #F5F5F5;
                border-color: #F5F5F5;
            }
            &.pending {
                color: #a7d5e7;
                border-color: #a7d5e7;
            }
            &.current {
                color: #ffa000;
                border-color: #ffa000;
            }
        }
    }
    #footer-container {
        background: #387c98;
        //background: #276f8b;
        color: white;
        ul {
            li {
                a {
                    color: #a7d5e7;
                    &:hover {
                        color: #78a8bb;
                    }
                }
            }
        }
    }
}

html.auth-layout {
    #header-extras {
        #header-live-chat {
            text-align:center;
            //border-radius: 2px;
            //box-shadow: rgba(0,147,39,.5) 2px 2px;
            background: #DFFFE8;
            border: 1px solid #009327;
            transition:all 200ms ease;

            &:hover {
                background: #D2F5DB;
            }
            a {
                display: block;
                padding: 5px 10px;
                text-decoration: none;
            }
            i {
                display: inline-block;
                vertical-align: middle;
                font-size:20pt;
                color:#009327;
            }
            span {
                display: inline-block;
                vertical-align: middle;
                margin-left: 5px;
                font-size: 14px;
                font-weight: bold;
                color:#009327;
            }
        }
        #header-customer-info {
            font-size: 10pt;
            text-align: right;

            .phone {
                margin-right: 5px;
            }
            .logout-link {
                margin-left: 5px;
            }
        }
    }
}

html.no-auth-layout {
    #signup-cta {
        padding: 10px 0;
        font-size: 12pt;
        font-family: 'Roboto Condensed', 'Arial Narrow', Arial, Helvetica, sans-serif;
        color: #333;

        a {
            color:#228ca5;
            &:hover {
                color:#03637a;
            }
        }
    }

    #header-extras {
        #header-call-us {
            font-size: 11pt;
            text-align: center;
        }
        #header-signup-info {
            font-size: 10pt;
            text-align: right;
        }
    }

    &.brand-il {
        #signup-cta {
            color: #F5F5F5;

            a {
                color:#F5F5F5;
                //text-decoration:none;
                &:hover {
                    color:#CCC;
                    //text-decoration:underline;
                }
            }
        }
    }

    &.brand-iq {
        #signup-cta {
            color: #F5F5F5;

            a {
                color:#a7d5e7;
                //text-decoration:none;
                &:hover {
                    color:#78a8bb;
                    //text-decoration:underline;
                }
            }
        }
    }

    #forgot-username-link, #forgot-password-link {
        margin-top: 5px;
    }

    /* Affiliate Signup */
    .ui3-container .affiliate-checkbox-list {
        width: 500px;
        margin: 10px auto;
    }

    .ui3-container .affiliate-checkbox-list li {
        list-style: none;
        display: inline-block;
        margin: 10px;
        min-width: 110px;
    }

    .ui3-container .affiliate-checkbox-list li label {
        font-weight: bold;
    }
}

@media print {
    #header-container, #header-inner-container,
    #footer-container, #footer-inner-container {
        display: none;
    }
}
// endregion
