html.serviceplan {
    #features-price {
        float: right;
        color: #FFF;
        text-align: center;
        width:90px;
        height:90px;
        /*background:transparent url('/Content/images/blue-seal.png') 0 0 no-repeat;*/
        .text {
            margin-top:27px;
            font-size:9pt;
            line-height:17px;
            transform: rotate(13deg);
            .price {
                font-size:18pt;
                font-weight:bold;
            }
        }
    }
    dd {
        font-size:11pt;
    }
}
