html.billing, html.creditcards {
    // Fix margin/padding display issues with buttons
    .ui3-button {
        display: inline-block;

        &.hidden, &.hide {
            display: none;
        }
    }

    .cvc-help {
        font-size: 10pt;
        padding-left: 2px;
    }
    .cvc-help-popup-content {
        width: 207px;
        margin: 0 auto;
    }

    #folio-settings-container {
        #folio-settings-table {
            width: 100%;
            border-spacing: 0;

            th {
                text-align: left;
                padding-bottom: 5px;
                border-bottom: 1px solid #BBB;
            }

            tr.header-spacer {
                td {
                    padding: 10px 0 0 0;  // Margin for header row
                }
            }

            td {
                padding-bottom: 20px;
            }

            th, td {
                vertical-align: bottom;

                &:not(:first-child) {
                    padding-left: 40px;
                }
            }

            .buttons {
                .folio-button {
                    margin-left: -3px;
//                    border-radius: 0;
                }
            }
        }
    }
}

#billing-invoice {
    width: 100%;


    td.invoice-billing-cycles {
        select {
            float: left;
            width: 50%;
        }

        .print-button {
            padding-left: 10px;
            float: left;
        }
    }

    .company-details-section, .customer-details-section {
        vertical-align: top;
        height: 200px;
        width: 50%;
    }

    .company-details-table {
        td {
            font-weight: normal;
            text-align: left;
            font-size: 10pt;
        }

        td.company-name {
            color: red;
            font-weight: bold;
            padding: 10px 0px;
            font-size: 14pt;
        }
    }



    .customer-details-table {
        td {
            padding: 5px;
        }

        td.customer-details-label {
            color: #333;
            font-weight: bold;
            text-align: right;
            vertical-align: top;
        }
    }

    .invoice-summary-section {
        height: 300px;
        vertical-align: top;
        width: 50%;

        .invoice-summary-table {
            width: 100%;

            th {
                text-align: left;
                border-bottom: 1px solid #CCC;
                padding-bottom: 5px
            }

            td {
                border-bottom: 1px solid #CCC;
                font-weight: normal;
                width: 50%;
                font-size: 12pt;
            }

            .summary-item-description {
                text-align: left;
                padding: 5px 5px;
            }

            .summary-item-amount {
                text-align: right;
                padding: 5px 5px;
            }

            .item-type-BalanceDue {
                background-color: #333;
                color: white;
            }

            td.summary-nothing-due {
                font-weight: bold;
                color: darkgreen;
                text-align: left;
                border: none;
            }
        }
    }

    .nationwide-language {
        text-align: left;
        font-weight: normal;
        font-size: 12pt;
    }

    .lead-detail-link {
        padding-bottom: 30px;
        padding-right: 70px;
    }

    .invoice-details-header {
        font-weight: bold;
        font-size: 12pt;
        border-bottom: 1px solid #333;
        padding: 0 0 10px;
        text-align: left;
    }

    .invoice-details {
        width: 100%;

        .invoice-details-purchases-header {
            font-weight: bolder;
            padding: 20px 0 10px;
            text-align: left;
        }

        .invoice-details-purchases {
            width: 100%;

            th {
                text-align: left;
                font-weight: bold;
                font-size: 11pt;
                padding: 4px 2px 4px 2px;
                border: 1px solid #CCC;
            }

            td {
                border: 1px solid #CCC;
                padding: 4px 2px 4px 2px;
                font-weight: lighter;
                font-size: 9pt;
                width: 12%;
                text-align: right;
            }

            td:nth-child(1) {
                width: 5%;
            }

            td:nth-child(2) {
                width: 45%;
            }
        }

        .invoice-details-returns {
            width: 40%;

            th {
                font-weight: bolder;
                padding: 20px 0 10px;
                text-align: left;
            }

            td {
                border: 1px solid #CCC;
                padding: 4px 2px 4px 2px;
                font-weight: lighter;
                font-size: 9pt;
            }

            td:nth-child(1) {
                width: 75%;
                text-align: left;
            }

            td:nth-child(2) {
                width: 25%;
            }
        }

        td.billing-invoice-total-cells {
            font-weight: bold;
            border-top: 2px solid #000000;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }

        .invoice-cell-left {
            text-align: left;
            float: left;
        }
    }
}

.affiliate-invoice {
    width: 100%;

    .invoice-notice {
        margin: 10px;
        color: #0a7f9b;
    }

    td.invoice-billing-cycles {
        select {
            float: left;
            width: 50%;
        }

        .print-button {
            padding-left: 10px;
            float: left;
        }
    }

    .company-details-section, .customer-details-section {
        vertical-align: top;
        height: 150px;
        width: 50% !important;
    }

    .company-details-table {
        td {
            font-weight: normal;
            text-align: left;
            font-size: 10pt;
        }

        td.company-name {
            color: red;
            font-weight: bold;
            padding: 10px 0px;
            font-size: 14pt;
        }
    }

    .customer-details-table {
        td {
            padding: 3px;
        }

        td.customer-details-label {
            color: #333;
            font-weight: bold;
            text-align: right;
            vertical-align: top;
        }
    }

    .invoice-summary-section {
        height: 300px;
        vertical-align: top;
        column-span: all;

        .invoice-summary-table {
            width: 80%;
            font-size: 11pt;

            th {
                text-align: left;
                border-bottom: 1px solid #CCC;
                white-space: nowrap;
                padding: 5px;
            }

            td {
                border-bottom: 1px solid #CCC;
                font-weight: normal;
                width: 50%;
            }

            .summary-item-description {
                text-align: left;
                padding: 5px 5px;
            }

            .summary-item-amount {
                text-align: right;
                padding: 5px 5px;
            }

            .summary-total {
                td {
                    background-color: #0a7f9b;
                    font-weight: bold;
                    color: white;
                }
            }
        }
    }
}